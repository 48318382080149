/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, NSFD){
	const a = 'is-active';
	const v = 'is-visible';

	// <共通>ファイルサイズ変換
	const fileSize = () => {
		const bytesToSize = (bytes) => {
			const sizes = ['Bytes', 'KB', 'MB', 'GB'];
			if (bytes === 0) return '0 Byte';
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			let convSize = Math.floor(bytes / Math.pow(1024, i)* Math.pow(10, 1)) / Math.pow(10, 1);
			// KBのとき、小数点切り捨て
			if (i === 1) convSize = Math.floor(convSize);
			return convSize + sizes[i];
		};
		$('.js-pdfsize').each((idx, ele) => {
			let thisSize = $(ele).text();
			$(ele).text(bytesToSize(thisSize));
		});
	}

	// <ニュース一覧>
	const releaseSearch = () => {
		let $map = location.pathname; 
		const latestYtxt = $('.rt_latest_year_month').text();
		//const oldestYtxt = $('.rt_oldest_year_month').text();
		let latestY = latestYtxt.split('.')[0];
		let latestYtxtMonth = latestYtxt.split('.')[1];
		// let oldestY = oldestYtxt.split('.')[0];
		// let oldestYtxtMonth = oldestYtxt.split('.')[1];
		if(latestYtxtMonth <= 3) {
			latestY -= 1;
		}
		// if(oldestYtxtMonth <= 3) {
		// 	oldestY -= 1;
		// }

		// 年号セレクトボックス生成
		for(let i = latestY; i >= 2016; i--) {
			$('.js-selectYear').append('<option value="/index/release/'+i+'/">'+i+'</<option>');
		}
		// 年度選択
		let $mapY = $map.split('/')[$map.split('/').length -2]
		if($mapY !== 'release')	{
			$('.js-selectYear').find('option').each((idx, ele) => {
				if($(ele).val().indexOf($mapY) !== -1) {
					$(ele).prop('selected', 'selected');
				}
			});
		}

		// 「すべて」にチェックを入れたらカテゴリーのチェックが消える
		$('input[value="all"]').on('click', (e) => {
			if($(e.currentTarget).prop('checked') === true) {
				$('input').not('[value="all"]').prop('checked', false).prop('disabled', true);
			} else {
				$('input').not('[value="all"]').prop('checked', false).prop('disabled', false);
			}
		});
		// カテゴリーにチェックを入れたら「すべて」のチェックが消える
		$('input').not('[value="all"]').on('click', (e) => {
			$('input[value="all"]').prop('checked', false);
		});
		
		// クリアボタン
		$('.js-search-clear').on('click', (e) => {
			$('input').prop('checked', false).prop('disabled', false);
			$('.js-selectYear').children('option:first-child').prop('selected','selected');
		});

		// 絞り込み
		$('.js-search-btn').on('click', (e) => {
			let $year = $('.mod-select select').find('option:selected').val();
			let catArr = [];
			let $href;
			$('.mod-check-group').find('input:checked').each((idx, ele) => {
				catArr.push($(ele).val());
			})

			// 「すべて」の場合はクエリストリングなし
			if(catArr.indexOf('all') !== -1) {
				$href = $year + '#newsList';
			} else {
				$href = $year + '?category=' + catArr.join('&category=') + '#newsList';
			}

			location.href = $href;
		});
	}
	// <ニュース詳細>Facebookシェアボタン
	const setShareLinks = () => {
		$('.js-share-item').each((idx, ele) => {
			const url = encodeURIComponent(document.URL);
			const title = encodeURIComponent(document.title);
			const $this = $(ele);
			const href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

			$this.attr('href', href);
		});
	};

	// -------------------------------------------------------------------------------- //
	if($('.js-pdfsize').length) {
		fileSize();
	}

	if($('.release-searchBlock').length) {
		releaseSearch();
	}

	// ニュース詳細
	if($('.release-detail').length) {
		setShareLinks();
	}


})(window.jQuery, window.NSFD);
